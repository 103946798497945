import fiveRedLogo from '../../images/brand files/5red_logo_light.svg';
import uk from '../../images/icons/united-kingdom-fg.png';
import de from '../../images/icons/germany-fg.png';
import pl from '../../images/icons/poland-fg.png';

function Footer() {
    return (
        <footer>
            
            <div className='footer-container container'>
                <div className="left-col">
                    <img src={fiveRedLogo} alt="5Red logo" />
                    <ul>
                        <li><h4>office:</h4> <address>Al. Zwycięstwa 241/13, 81-531 Gdynia, Poland </address></li>
                        <li><h4>office:</h4> <address>Ul. Ładna 3, 55-093 Kiełczów, Poland</address></li>
                        <li><h4>website:</h4> <a href="https://5red.io/" target="_blank" rel="noreferrer">www.5red.io</a></li>
                    </ul>
                </div>
                <div className="right-col">
                    <h3>Get in touch</h3>
                    <ul>
                        <li><h4>email:</h4> <a href="mailto:contact@5red.io">contact@5red.io</a></li>
                        <li><h4>phone:</h4> 
                            <a id='de' href="tel:+49(6159)275-9999">+49 (6159) 275-9999 <img className='flag-img' src={de} alt="DE flag" /></a> 

                            <a id='uk' href="tel:+48784-586-139">+48 784-586-139<img className='flag-img' src={pl} alt="PL flag" /><img className='flag-img' src={uk} alt="UK flag" /></a>
                        </li>
                        <li><h4>postal address:</h4> <address>Al. Zwycięstwa 241/13, 81-531 Gdynia, Poland</address></li>
                    </ul>
                </div>
            </div>
            <p className="copyright">All rights reserved. &copy; 5red sp. z o.o. <time dateTime="2023">2023</time></p>
        </footer>
    );
}


export default Footer;