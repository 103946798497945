import {motion, AnimatePresence, useInView} from "framer-motion";
import {useState, useEffect, useRef} from 'react';
import { MouseParallaxContainer, MouseParallaxChild } from "react-parallax-mouse";
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Controller, Navigation } from "swiper";
import 'swiper/css';
import { useMediaQuery } from 'react-responsive';


import buildImg from '../../images/illustrations/build.png';
import runImg from '../../images/illustrations/run.png';
import deployImg from '../../images/illustrations/deploy.png';

import hybridImg from '../../images/icons/hybrid.png';
import privateImg from '../../images/icons/private.png';
import publicImg from '../../images/icons/public.png';
import customerImg from '../../images/icons/customer.png';

import flexIco from '../../images/icons/flexibility-ico.png';
import scalaIco from '../../images/icons/scalability-ico.png';
import isoIco from '../../images/icons/isolated-ico.png';
import stackIco from '../../images/icons/stack-ico.png';
import moniIco from '../../images/icons/monitoring-ico.png';

import sheldon from '../../images/illustrations/sheldon-big.svg';
import closeIco from '../../images/icons/close-mark.png';

import openMark from '../../images/icons/angle-right.png'

import ansible from '../../images/logos/ansible.png';
import aws from '../../images/logos/aws.png';
import azure from '../../images/logos/azure.png';
import codepipeline from '../../images/logos/codepipeline.png';
import docker from '../../images/logos/docker.png';
import github from '../../images/logos/github.png';
import gitlab from '../../images/logos/gitlab.png';
import googleCloud from '../../images/logos/google-cloud.png';
import grafanaLoki from '../../images/logos/grafana-loki.png';
import grafana from '../../images/logos/grafana.png';
import helm from '../../images/logos/helm.png';
import jenkins from '../../images/logos/jenkins.png';
import kubernetes from '../../images/logos/kubernetes.png';
import nexus from '../../images/logos/nexus.png';
import prometheus from '../../images/logos/prometheus.png';
import redHat from '../../images/logos/red-hat.png';
import sonarqube from '../../images/logos/sonarqube.png';
import terraform from '../../images/logos/terraform.png';

import MSPartner from '../../images/partners/MSPartner.png';
import Lenovo_IDP from '../../images/partners/Lenovo_IDP_partner.png';
import Lenovo_ISP from '../../images/partners/Lenovo_ISP_partner.png';
import aws_p from '../../images/partners/aws.png';
import azure_p from '../../images/partners/azure.png';
import cisco from '../../images/partners/cisco.png';
import cisco_mak from '../../images/partners/cisco_partner_logo.webp';
import dell_p from '../../images/partners/dt-partnerprogram-gray.webp';

import worldwide from '../../images/illustrations/worldwide.svg';

import mutlaq from '../../images/partners/mutlaq.svg';
import fiveRed from '../../images/partners/5red.svg';
import jungwind from '../../images/partners/jungwind.svg';
import prait from '../../images/partners/prait.svg';

import worldMap from '../../images/illustrations/world-map.svg';

SwiperCore.use([Controller, Navigation]);

function FadeInWhenVisible({ children }) {
  return (
    <motion.div
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true }}
      transition={{ duration: .5 }}
      variants={{
        visible: { opacity: 1, y: '0%' },
        hidden: { opacity: 0, y: '100px'}
      }}
    >
      {children}
    </motion.div>
  );
}


function HomePage() {
    const [isOpen, setIsOpen] = useState(false);
    const swiperPlans = useMediaQuery({ minWidth: 620, maxWidth: 1200});
    const swiperBuildRunDeploy = useMediaQuery({maxWidth: 620});
    const isMobile = useMediaQuery({maxWidth: 620});
    const parallaxDisabled = useMediaQuery({maxWidth: 1250});

    const [firstSwiper, setFirstSwiper] = useState(null);
    const [secondSwiper, setSecondSwiper] = useState(null);
    const [swiperCurrentIndex, setSwiperCurrentIndex] = useState('index-0');

    
    

    useEffect(() => {
      if (isOpen) {
        document.body.classList.add('prev-scrolling');
      } else {
        document.body.classList.remove('prev-scrolling');
      }
    }, [isOpen]);

    useEffect(() => {
      function handleEscClose(event) {
        if (event.keyCode === 27) {
          setIsOpen(false);
        }
      }
  
      if (isOpen) {
        document.addEventListener('keydown', handleEscClose);
      }
  
      return () => {
        document.removeEventListener('keydown', handleEscClose);
      };
    }, [isOpen, setIsOpen]);

    const devEl = useRef(null);
    const infraEl = useRef(null);
    const speedEl = useRef(null);

    const devInView = useInView(devEl);
    const infraInView = useInView(infraEl);
    const speedInView = useInView(speedEl);

    useEffect(() => {
      if(devInView) {
        let devInt = setInterval(() => {
          if (parseInt(devEl.current.textContent, 10) >= 68) {
            return clearInterval(devInt);
          } else {
            devEl.current.textContent++;
          }
        }, 30);
      }
    }, [devInView]);
    useEffect(() => {
      if(infraInView ) {
        let infInt = setInterval(() => {
          if (parseInt(infraEl.current.textContent, 10) >= 24) {
            return clearInterval(infInt);
          } else {
            infraEl.current.textContent++;
          }
        }, 50);
      }
    }, [infraInView ]);
    useEffect(() => {
      if(speedInView) {
        let speedInt = setInterval(() => {
          if (parseInt(speedEl.current.textContent, 10) >= 5) {
            return clearInterval(speedInt);
          } else {
            speedEl.current.textContent++;
          }
        }, 300);
      }
    }, [speedInView]);

    return (
        <div className="content">
            <section className="slogan">
                  <motion.h1 
                  className="title"
                  initial={{ opacity: 0}}
                  animate={{opacity: 1}}
                  transition={{delay: .3, duration: 1 }}
                  >
                      Effortless
                      <span>work</span>
                  </motion.h1>
                  <motion.p className='semi-title'
                  initial={{ opacity: 0}}
                  animate={{opacity: 1}}
                  transition={{delay: 1, duration: 1 }}>Exceptional results</motion.p>
            </section>

            <section className="build-deploy-run">
              {swiperBuildRunDeploy ? (
                <>
                  <div className="about-product">
                    <div className="container">
                      <motion.h2 className="prod-desc"
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true }}
                        transition={{ duration: 1 }}
                        variants={{
                          visible: { opacity: 1, y: '0' },
                          hidden: { opacity: 0, y: '30px'}
                        }}
                      >
                          Discover <span>the power</span> of Sheldonstack
                        </motion.h2>

                      <motion.p className="prod-desc"
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true }}
                        transition={{ duration: 1, delay: 1 }}
                        variants={{
                          visible: { opacity: 1},
                          hidden: { opacity: 0}
                        }}
                      >
                          a cutting-edge powered by internal and 3rd party AI solutions tool that delivers end-to-end solutions for Continuous Integration and Continuous Delivery. 
                          Take the first step towards seamless software development with Sheldonstack today!
                      </motion.p>
                    </div>
                  </div>
                      
                  <FadeInWhenVisible>
                      
                    <Swiper
                      onSwiper={setSecondSwiper}
                      controller={{ control: firstSwiper }}
                      slidesPerView={3}
                      centeredSlides={true}
                      slideToClickedSlide={true}
                      onSlideChange={(e) => setSwiperCurrentIndex(`index-${e.activeIndex}`)}
                      className={'header-swiper ' + swiperCurrentIndex}
                    >
                      <SwiperSlide><h2 id="build">Build</h2></SwiperSlide>
                      <SwiperSlide><h2 id="deploy">Deploy</h2></SwiperSlide>
                      <SwiperSlide><h2 id="run">Run</h2></SwiperSlide>
                    </Swiper>

                        <Swiper
                        onSwiper={setFirstSwiper}
                        controller={{ control: secondSwiper }}
                        slidesPerView={1}
                        centeredSlides={true}
                      >
                        <SwiperSlide>
                          <article className="container build">
                              <div>
                                  <span>Sheldonstack - Ready to go in minutes!</span>
                                  <p>
                                      With Sheldonstack, there's no need to wait for hours to launch your
                                      infrastructure. It's a 
                                      <strong> fully automated </strong> 
                                      product that's ready to go with just one click and only takes a few minutes to
                                      set up.
                                  </p>
                                  <br/>
                                  <p>
                                      With Sheldonstack you can
                                      <strong> quickly and easily </strong>
                                      launch your infrastructure, save time and effort with its user-friendly
                                      interface, and rest assured that
                                      <strong> everything is automated and running smoothly </strong>.
                                  </p>
                                  <br/>
                                  <p>
                                      Don't wait any longer, join the ranks of satisfied Sheldonstack users and launch
                                      your infrastructure today in just a
                                      <strong> few minutes! </strong>
                                  </p>
                              </div>

                          </article>
                        </SwiperSlide>
                        <SwiperSlide>

                          <article className="container deploy">

                              <div>
                                  <span>Have you ever thought you need a devops Team?
                                  </span>
                                  <p>
                                      With Sheldonstack you don’t!
                                  </p>
                                  <br/>
                                  <p>
                                      You'll receive a
                                      <strong> complete set of tools </strong>
                                      for working with your infrastructure, starting with a
                                      <strong> Git repository</strong>, through
                                      <strong> CI/CD tools</strong>, to
                                      <strong> resource monitoring applications</strong>. It all means in minutes you
                                      will get all the tools the other deploy in months, sometimes maybe in years.
                                  </p>
                                  <br/>
                                  <p>
                                      And what’s important?
                                      <strong> You will be able to manage this deployment on your own</strong>. If you
                                      need any tools, you can select it from the list and that’s it. After several
                                      minutes it works, it’s configured
                                      <strong> according to your requirements, puproses and company specific restrictions
                                      </strong>.
                                  </p>
                              </div>
                          </article>
                        </SwiperSlide>
                        <SwiperSlide>

                            <article className="container run">
                                <div>
                                    <span>Upgrade to Sheldonstack's GUI</span>
                                    <p>
                                        The Sheldonstack GUI makes it simple and efficient to manage your infrastructure
                                        with its
                                        <strong> user-friendly design </strong>
                                        and
                                        <strong> visual representations of data</strong>. With its powerful features, you
                                        can take control of your infrastructure and make informed decisions with ease.
                                        Invest in Sheldonstack's GUI for a
                                        <strong> streamlined and enjoyable management experience</strong>.
                                    </p>
                                    <br/>
                                    <p>
                                        The Sheldonstack GUI not only provides full control over your infrastructure,
                                        but also gives access to
                                        <strong> comprehensive monitoring capabilities and allows for tracking of service
                                            availability history</strong>.
                                        This makes it a complete solution for managing your infrastructure and ensuring
                                        its smooth functioning.
                                    </p>
                                </div>

                            </article>
                        </SwiperSlide>
                      </Swiper>
                    </FadeInWhenVisible>
                </>
              ) : (
                <>
                <div className="about-product">
                  <div className="container">
                      <motion.h2 className="prod-desc"
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true }}
                        transition={{ duration: 1 }}
                        variants={{
                          visible: { opacity: 1, y: '0' },
                          hidden: { opacity: 0, y: '30px'}
                        }}
                      >
                          Discover <span>the power</span> of Sheldonstack
                        </motion.h2>

                        <motion.p className="prod-desc"
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true }}
                        transition={{ duration: 1, delay: 1 }}
                        variants={{
                          visible: { opacity: 1},
                          hidden: { opacity: 0}
                        }}
                      >
                          a cutting-edge powered by internal and 3rd party AI solutions tool that delivers <br/>end-to-end solutions for Continuous Integration and Continuous Delivery. 
                          <br/>Take the first step towards seamless software development with Sheldonstack today!
                      </motion.p>
                    </div>
                </div>
                  <FadeInWhenVisible>
                    <article className="container build">
                        <div>
                            <h2>Build</h2>
                            <span>Sheldonstack - Ready to go in minutes!</span>
                            <p>
                                With Sheldonstack, there's no need to wait for hours to launch your
                                infrastructure. It's a 
                                <strong> fully automated </strong> 
                                product that's ready to go with just one click and only takes a few minutes to
                                set up.
                            </p>
                            <br/>
                            <p>
                                With Sheldonstack you can
                                <strong> quickly and easily </strong>
                                launch your infrastructure, save time and effort with its user-friendly
                                interface, and rest assured that
                                <strong> everything is automated and running smoothly </strong>.
                            </p>
                            <br/>
                            <p>
                                Don't wait any longer, join the ranks of satisfied Sheldonstack users and launch
                                your infrastructure today in just a
                                <strong> few minutes! </strong>
                            </p>
                        </div>
                        <img src={buildImg} alt="creative smiling man with a pen"/>
                    </article>
                  </FadeInWhenVisible>
                  <FadeInWhenVisible>
                    <article className="container deploy">
                        <img src={deployImg} alt="happy man with a laptop"/>
                        <div>
                            <h2>Deploy</h2>
                            <span>Have you ever thought you need a devops Team?
                            </span>
                            <p>
                                With Sheldonstack you don’t!
                            </p>
                            <br/>
                            <p>
                                You'll receive a
                                <strong> complete set of tools </strong>
                                for working with your infrastructure, starting with a
                                <strong> Git repository</strong>, through
                                <strong> CI/CD tools</strong>, to
                                <strong> resource monitoring applications</strong>. It all means in minutes you
                                will get all the tools the other deploy in months, sometimes maybe in years.
                            </p>
                            <br/>
                            <p>
                                And what’s important?
                                <strong> You will be able to manage this deployment on your own</strong>. If you
                                need any tools, you can select it from the list and that’s it. After several
                                minutes it works, it’s configured
                                <strong> according to your requirements, puproses and company specific restrictions
                                </strong>.
                            </p>
                        </div>
                    </article>
                    </FadeInWhenVisible>


                    <FadeInWhenVisible>
                    <article className="container run">
                        <div>
                            <h2>Run</h2>
                            <span>Upgrade to Sheldonstack's GUI</span>
                            <p>
                                The Sheldonstack GUI makes it simple and efficient to manage your infrastructure
                                with its
                                <strong> user-friendly design </strong>
                                and
                                <strong> visual representations of data</strong>. With its powerful features, you
                                can take control of your infrastructure and make informed decisions with ease.
                                Invest in Sheldonstack's GUI for a
                                <strong> streamlined and enjoyable management experience</strong>.
                            </p>
                            <br/>
                            <p>
                                The Sheldonstack GUI not only provides full control over your infrastructure,
                                but also gives access to
                                <strong> comprehensive monitoring capabilities and allows for tracking of service
                                    availability history</strong>.
                                This makes it a complete solution for managing your infrastructure and ensuring
                                its smooth functioning.
                            </p>
                        </div>
                        <img src={runImg} alt="Man working in office"/>
                    </article>
                  </FadeInWhenVisible>
                </>
              )}

            </section>

            <section className="our-partners">
                <motion.h2
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true }}
                  transition={{ duration: .7, delay: .2 }}
                  variants={{
                    visible: { opacity: 1, y: 0},
                    hidden: { opacity: 0, y: '10px'}
                  }}
                >
                  Our partners
                </motion.h2>

                <div className="partners-container container">
                  
                  <motion.img
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true }}
                    transition={{ duration: .5, delay: .6 }}
                    variants={{
                      visible: { opacity: 1, y: 0},
                      hidden: {opacity: 0, y: 50}
                    }}
                    src={Lenovo_IDP} alt="illsutration of sheldonstack" id="sheldon-img" />

                  <motion.img
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true }}
                    transition={{ duration: .5, delay: 1.2 }}
                    variants={{
                      visible: { opacity: 1, y: 0},
                      hidden: {opacity: 0, y: 50}
                    }}
                    src={Lenovo_ISP} alt="illsutration of sheldonstack" id="sheldon-img" />
                    <motion.img
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true }}
                    transition={{ duration: .5, delay: 1.1 }}
                    variants={{
                      visible: { opacity: 1, y: 0},
                      hidden: {opacity: 0, y: 50}
                    }}
                    src={dell_p} alt="illsutration of sheldonstack" id="sheldon-img" />
                  <motion.img
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true }}
                    transition={{ duration: .5, delay: .5 }}
                    variants={{
                      visible: { opacity: 1, y: 0},
                      hidden: {opacity: 0, y: 50}
                    }}
                    src={aws_p} alt="illsutration of sheldonstack" id="sheldon-img" />
                  <motion.img
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true }}
                    transition={{ duration: .5, delay: 1.1 }}
                    variants={{
                      visible: { opacity: 1, y: 0},
                      hidden: {opacity: 0, y: 50}
                    }}
                    src={azure_p} alt="illsutration of sheldonstack" id="sheldon-img" />
                  <motion.img
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true }}
                    transition={{ duration: .5, delay: .9 }}
                    variants={{
                      visible: { opacity: 1, y: 0},
                      hidden: {opacity: 0, y: 50}
                    }}
                    src={cisco} alt="illsutration of sheldonstack" id="sheldon-img" />
                  <motion.img
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true }}
                    transition={{ duration: .5, delay: .6 }}
                    variants={{
                      visible: { opacity: 1, y: 0},
                      hidden: {opacity: 0, y: 50}
                    }}
                    src={cisco_mak} alt="illsutration of sheldonstack" id="sheldon-img" />
                  <motion.img
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true }}
                    transition={{ duration: .5, delay: .7 }}
                    variants={{
                      visible: { opacity: 1, y: 0},
                      hidden: {opacity: 0, y: 50}
                    }}
                    src={MSPartner} alt="illsutration of sheldonstack" id="sheldon-img" />
                  
                </div>
            </section>

            <section className="product-details">
            <motion.img
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: .5, delay: .2 }}
            variants={{
              visible: { opacity: 1, y: 0},
              hidden: {opacity: 0, y: 50}
            }}
            src={sheldon} alt="illsutration of sheldonstack" id="sheldon-img" />
              <div className="bg-container">

                <article className="environments container">
                <motion.h2
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true }}
                  transition={{ duration: .7, delay: .2 }}
                  variants={{
                    visible: { opacity: 1, y: 0},
                    hidden: { opacity: 0, y: '10px'}
                  }}
                >
                  Freedom of choice
                </motion.h2>
                <motion.p
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true }}
                  transition={{ duration: .7, delay: .5 }}
                  variants={{
                    visible: { opacity: 1, y: 0},
                    hidden: { opacity: 0, y: '10px'}
                  }}
                >
                        The product's
                        <strong> flexibility </strong>
                        and
                        <strong> portability </strong>
                        offer several
                        <strong> advantages </strong>, such as
                        <strong> the ability </strong>
                        to run on various hardware platforms and
                        <strong> the freedom </strong>
                        to use it in different
                        <strong> environments</strong>.
                </motion.p>

                    <div className="boxes">
                        <motion.div 
                          initial="hidden"
                          whileInView="visible"
                          viewport={{ once: true }}
                          transition={{ type: 'spring', duration: 1 }}
                          variants={{
                            visible: { opacity: 1, scale: 1},
                            hidden: { opacity: 0, scale: .2}
                          }}
                        className="hybrid">
                            <img src={hybridImg} alt=""/>
                            <h3>Hybrid</h3>
                            <p>Public cloud infrastructure and private resources provided by 5red company.</p>
                        </motion.div>

                        <motion.div 
                          initial="hidden"
                          whileInView="visible"
                          viewport={{ once: true }}
                          transition={{ type: 'spring', duration: 1}}
                          variants={{
                            visible: { opacity: 1, scale: 1},
                            hidden: { opacity: 0, scale: .2}
                          }}
                        className="private">
                            <img src={privateImg} alt=""/>
                            <h3>Private</h3>
                            <p>Infrastructure fully provided by 5red company. VPN Site to Site connection
                                with your company.</p>
                        </motion.div>

                        <motion.div 
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true }}
                        transition={{ type: 'spring', duration: 1}}
                        variants={{
                          visible: { opacity: 1, scale: 1},
                          hidden: { opacity: 0, scale: .2}
                        }}
                        className="public">
                            <img src={publicImg} alt=""/>
                            <h3>Public</h3>
                            <p>Infrastructure resources fully provided by public cloud operators like AWS,
                                Azure, GCP, Alibaba, Huawei, etc.</p>
                        </motion.div>

                        <motion.div
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true }}
                        transition={{ type: 'spring', duration: 1}}
                        variants={{
                          visible: { opacity: 1, scale: 1},
                          hidden: { opacity: 0, scale: .2}
                        }}
                        className="customer">
                            <img src={customerImg} alt=""/>
                            <h3>Customer's infra</h3>
                            <p>Infrastructure fully provided by a customer. Hyper-V, VCenter, Openstack,
                                Bare Metal, etc.</p>
                        </motion.div>
                    </div>
                </article>

                <article className="more-info">
                  <motion.h2
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true }}
                    transition={{ duration: .5, delay: .2 }}
                    variants={{
                      visible: { opacity: 1, y: '0'},
                      hidden: { opacity: 0, y: '20px'}
                    }}
                  >
                    Five steps to better tommorow
                  </motion.h2>
                    

                    <AnimatePresence>
                      {
                        isOpen && (
                          <motion.div className='overlay'
                            transition={{ duration: .4}}
                            initial={{ opacity: 0}}
                            animate={{ opacity: 1}}
                            exit={{opacity: 0}}>
                          </motion.div>
                        )
                      }
                    </AnimatePresence>
                    
                    <div className="boxes container-l">

                      <motion.div
                          transition = {{type: "spring", stiffness: 170, damping: 20}}
                          layout="layout" 
                          onClick={isOpen ? null : () => setIsOpen(2) }
                          id ="monitoring"
                          className={isOpen === 2 ? "card open" : "card"}
                          initial="hidden"
                          whileInView="visible"
                          viewport={{ once: true }}
                          variants={{
                            visible: { opacity: 1, scale: 1},
                            hidden: { opacity: 0, scale: .2}
                          }}
                          >
                          <AnimatePresence>
                            <div>
                              <motion.h3 layout="position">
                                <img src={moniIco} alt="icon" />
                                AI Monitoring 24/7
                              </motion.h3>
                              <motion.p layout="layout" transition={{duration: .3}}>
                                Our tool leverages <strong>AI to manage costs effectively</strong>, providing a range of benefits{isOpen === 2 ? ". By utilizing advanced algorithms, it enables accurate cost forecasting, reduces the risk of overspending, and optimizes resource allocation." : '...'} 
                              </motion.p>

                              {isMobile ? null : 
                                isOpen === 2 ?
                                <motion.button whileHover={{scale: 1.5}} className="close" onClick={() => setIsOpen(false)}>
                                  <img src={closeIco} alt="close button" />
                                </motion.button>
                              :
                                <motion.span className="more">
                                    read more
                                </motion.span>
                              }
                                
                              {
                                isOpen === 2 && (
                                      <motion.div 
                                        layout="layout" 
                                        transition={{delay: .3}}
                                        initial={{ opacity: 0}}
                                        animate={{ opacity: 1}}
                                        exit={{opacity: 0}}>
                                        <p>
                                          Additionally, the <strong>AI-driven</strong> approach ensures <strong>real-time monitoring</strong> and <strong>quick identification of cost-saving opportunities</strong>, leading to more informed decision-making and overall financial efficiency.
                                        </p>
                                        <p>With SheldonStack, you can focus on your business, knowing that your technology infrastructure is in <strong>good hands</strong>. Experience unparalleled uptime, performance, and peace of mind with SheldonStack.</p>
                                      </motion.div>
                                  )
                              }
                              
                            </div>
                          </AnimatePresence>

                          {!isMobile ? null : 
                            isOpen === 2 ?
                              <motion.button  className="close-mobile" onClick={() => setIsOpen(false)}>
                                <img src={closeIco} alt="close button" />
                              </motion.button>
                            :
                            <motion.img src={openMark} alt=""></motion.img>
                          }
                      </motion.div>

                      <motion.div
                          transition = {{type: "spring", stiffness: 170, damping: 20}}
                          layout="layout" 
                          id="flexibility"
                          onClick={isOpen ? null : () => setIsOpen(3) }
                          className={isOpen === 3 ? "card open" : "card"}
                          initial="hidden"
                          whileInView="visible"
                          viewport={{ once: true }}
                          variants={{
                            visible: { opacity: 1, scale: 1},
                            hidden: {opacity: 0, scale: .2}
                          }}
                          >
                          <AnimatePresence>
                            <div>

                              <motion.h3 layout="position">
                                <img src={flexIco} alt="icon" />
                                Flexibility
                              </motion.h3>
                              <motion.p layout="layout" transition={{duration: .3}}>
                                SheldonStack is super flexible and can operate anywhere the customer desiress{isOpen === 3 ? ". Here are some benefits of this type of solution:" : '...'}
                              </motion.p>
                              {isMobile ? null : 
                                isOpen === 3 ?
                                <motion.button whileHover={{scale: 1.5}} className="close" onClick={() => setIsOpen(false)}>
                                <img src={closeIco} alt="close button" />
                              </motion.button>  
                            :
                              <motion.span className="more">
                                  read more
                              </motion.span>
                          }
                                
                              {
                                isOpen === 3 && (
                                      <motion.div 
                                        layout="layout" 
                                        transition={{delay: .3}}
                                        initial={{ opacity: 0}}
                                        animate={{ opacity: 1}}
                                        exit={{opacity: 0}}>

                                          <ol>
                                            <li>Provides maximum flexibility in terms of deployment options.</li>
                                            <li>Offers unparalleled portability, allowing it to be used in a variety of environments.</li>
                                            <li>Allows customers to have full control over their infrastructure.</li>
                                            <li>Provides a cost-effective solution with the ability to run on their own hardware.</li>
                                            <li>Fully Public Cloud Ready!</li>
                                          </ol>
                                      </motion.div>
                                  )
                              }
                            </div>
                          </AnimatePresence>

                          {!isMobile ? null : 
                            isOpen === 3 ?
                              <motion.button className="close-mobile" onClick={() => setIsOpen(false)}>
                                <img src={closeIco} alt="close button" />
                              </motion.button>
                            :
                            <motion.img src={openMark} alt=""></motion.img>
                          }
                      </motion.div>

                      <motion.div
                          transition = {{type: "spring", stiffness: 170, damping: 20}}
                          layout="layout" 
                          id="isolated-resources"
                          onClick={isOpen ? null : () => setIsOpen(4) }
                          className={isOpen === 4 ? "card open" : "card"}
                          initial="hidden"
                          whileInView="visible"
                          viewport={{ once: true }}
                          variants={{
                            visible: { opacity: 1, scale: 1},
                            hidden: {opacity: 0, scale: .2}
                          }}
                          >
                          <AnimatePresence>
                            <div>

                              <motion.h3 layout="position">
                                <img src={isoIco} alt="icon" />
                                Isolated resources
                              </motion.h3>
                              <motion.p layout="layout" transition={{duration: .3}}>
                              <strong>Privacy and security</strong> are the foundation of SheldonStack, which operates on{isOpen === 4 ? " fully private resources and access to the infrastructure is provided via VPN connection." : '...'}
                              </motion.p>
                              {isMobile ? null : 
                                isOpen === 4 ?
                                <motion.button whileHover={{scale: 1.5}} className="close" onClick={() => setIsOpen(false)}>
                                <img src={closeIco} alt="close button" />
                              </motion.button>  
                            :
                              <motion.span className="more">
                                  read more
                              </motion.span>
                          }
                                
                              {
                                isOpen === 4 && (
                                      <motion.div 
                                        layout="layout" 
                                        transition={{delay: .3}}
                                        initial={{ opacity: 0}}
                                        animate={{ opacity: 1}}
                                        exit={{opacity: 0}}>

                                        <p>By default, there is no access to resources from the internet, but upon request, <strong>some resources can be made public</strong>.
                                        </p>
                                        <p>We encourage our clients to consider this option as it offers numerous benefits, such as <strong>increased control over their infrastructure, enhanced security and privacy, and peace of mind knowing their data is protected</strong>. 
                                        </p>
                                        <p>
                                          By choosing SheldonStack, you can rest assured that <strong>your infrastructure is secure and your data is protected</strong>.
                                          </p>
                                      </motion.div>
                                  )
                              }
                            </div>
                          </AnimatePresence>


                          {!isMobile ? null : 
                            isOpen === 4 ?
                              <motion.button className="close-mobile" onClick={() => setIsOpen(false)}>
                                <img src={closeIco} alt="close button" />
                              </motion.button>
                            :
                            <motion.img src={openMark} alt=""></motion.img>
                          }
                      </motion.div>

                      <motion.div
                          transition = {{type: "spring", stiffness: 170, damping: 20}}
                          layout="layout" 
                          id="custom-stack"
                          onClick={isOpen ? null : () => setIsOpen(1) }
                          className={isOpen === 1 ? "card open" : "card"}
                          initial="hidden"
                          whileInView="visible"
                          viewport={{ once: true }}
                          variants={{
                            visible: { opacity: 1, scale: 1},
                            hidden: {opacity: 0, scale: .2}
                          }}
                          >
                          <AnimatePresence>
                            <div>
 
                              <motion.h3 layout="position">
                                <img src={stackIco} alt="icon" />
                                Custom stack
                              </motion.h3>
                              <motion.p layout="layout" transition={{duration: .3}}>
                              You have full freedom in choosing your technology stack. In addition to{isOpen === 1 ? " the default configuration, we offer you complete flexibility and the ability to customize the product to your needs and requirements. Here are some benefits of this approach:" : '...'}
                              </motion.p>
                              {isMobile ? null : 
                                isOpen === 1 ?
                                <motion.button whileHover={{scale: 1.5}} className="close" onClick={() => setIsOpen(false)}>
                                <img src={closeIco} alt="close button" />
                              </motion.button>  
                            :
                              <motion.span className="more">
                                  read more
                              </motion.span>
                          }
                                
                              {
                                isOpen === 1 && (
                                      <motion.div 
                                        layout="layout" 
                                        transition={{delay: .3}}
                                        initial={{ opacity: 0}}
                                        animate={{ opacity: 1}}
                                        exit={{opacity: 0}}>

                                          <ol>
                                            <li>Provides greater control over the technology used.</li>
                                            <li>Allows for a tailored solution that meets specific needs.</li>
                                            <li>Enables integration with existing systems and technologies.</li>
                                            <li>Offers improved performance and efficiency.</li>
                                          </ol>

                                          <p>We believe this level of customization will provide you with the best possible solution for your needs.</p>
                                      </motion.div>
                                  )
                              }
                            </div>
                          </AnimatePresence>


                          {!isMobile ? null : 
                            isOpen === 1 ?
                              <motion.button className="close-mobile" onClick={() => setIsOpen(false)}>
                                <img src={closeIco} alt="close button" />
                              </motion.button>
                            :
                            <motion.img src={openMark} alt=""></motion.img>
                          }
                      </motion.div>

                      <motion.div
                          transition = {{type: "spring", stiffness: 170, damping: 20}}
                          layout="layout" 
                          id="scalability"
                          onClick={isOpen ? null : () => setIsOpen(5) }
                          className={isOpen === 5 ? "card open" : "card"}
                          initial="hidden"
                          whileInView="visible"
                          viewport={{ once: true }}
                          variants={{
                            visible: { opacity: 1, scale: 1},
                            hidden: {opacity: 0, scale: .2}
                          }}
                          >
                          <AnimatePresence>
                            <div>

                              <motion.h3 layout="position">
                                <img src={scalaIco} alt="icon" />
                                Unlimited scalability
                              </motion.h3>
                              <motion.p layout="layout" transition={{duration: .3}}> 
                              Choosing an infrastructure with unlimited scalability has many benefits{isOpen === 5 ? ":" : '...'}
                              </motion.p>
                              {isMobile ? null : 
                                isOpen === 5 ?
                                  <motion.button whileHover={{scale: 1.5}} className="close" onClick={() => setIsOpen(false)}>
                                    <img src={closeIco} alt="close button" />
                                  </motion.button>  
                                :
                                  <motion.span className="more">
                                      read more
                                  </motion.span>
                              }
                                
                              {
                                isOpen === 5 && (
                                      <motion.div 
                                        layout="layout" 
                                        transition={{delay: .3}}
                                        initial={{ opacity: 0}}
                                        animate={{ opacity: 1}}
                                        exit={{opacity: 0}}>

                                          <ol>
                                            <li>Supports business growth without the need for extensive planning and capital expenditures.</li>
                                            <li>Offers seamless scalability without disruption to operations.</li>
                                            <li>Ensures that your infrastructure can accommodate increased demand.</li>
                                          </ol>

                                          <p>By opting for an infrastructure with unlimited scalability, you can focus on growing your business, knowing that your technology infrastructure can keep up with your needs. Investing in a scalable solution now can help you avoid future complications and costs down the line.</p>
                                      </motion.div>
                                  )
                              }
                            </div>
                          </AnimatePresence>


                          {!isMobile ? null : 
                            isOpen === 5 ?
                              <motion.button className="close-mobile" onClick={() => setIsOpen(false)}>
                                <img src={closeIco} alt="close button" />
                              </motion.button>
                            :
                            <motion.img src={openMark} alt=""></motion.img>
                          }
                      </motion.div>
                    </div>

                </article>
              </div>
            </section>
            
            <section className="brands">
              {
                parallaxDisabled ? (
                    <div className="container-brands">
                      <motion.h2
                       initial="hidden"
                       whileInView="visible"
                       viewport={{ once: true }}
                       transition={{ type: 'spring', duration: .6, delay: .3}}
                       variants={{
                         visible: { opacity: 1, scale: 1},
                         hidden: { opacity: 0, scale: .5}
                       }}>Some of the <span>most popular tools</span> <b>we use</b></motion.h2>

                          <motion.img initial="hidden"
                          whileInView="visible"
                          viewport={{ once: true }}
                          transition={{ type: 'spring', duration: .6, delay: .8}}
                          variants={{
                            visible: { scale: .7},
                            hidden: { opacity: 0, scale: 0}
                          }} src={ansible} className='ansible parallax-child' alt="ansible logo" />
                        
                          <motion.img initial="hidden"
                          whileInView="visible"
                          viewport={{ once: true }}
                          transition={{ type: 'spring', duration: .6, delay: .9}}
                          variants={{
                            visible: { scale: .7},
                            hidden: { opacity: 0, scale: 0}
                          }} src={aws} className='aws parallax-child' alt="aws logo" />
                        
                          <motion.img initial="hidden"
                          whileInView="visible"
                          viewport={{ once: true }}
                          transition={{ type: 'spring', duration: .6, delay: 1}}
                          variants={{
                            visible: { scale: .7},
                            hidden: { opacity: 0, scale: 0}
                          }} src={azure} className='azure parallax-child' alt="azure logo" />
                        
                          <motion.img initial="hidden"
                          whileInView="visible"
                          viewport={{ once: true }}
                          transition={{ type: 'spring', duration: .6, delay: .8}}
                          variants={{
                            visible: { scale: .7},
                            hidden: { opacity: 0, scale: 0}
                          }} src={codepipeline} className='codepipeline parallax-child' alt="codepipeline logo" />
                        
                          <motion.img initial="hidden"
                          whileInView="visible"
                          viewport={{ once: true }}
                          transition={{ type: 'spring', duration: .6, delay: .7}}
                          variants={{
                            visible: { scale: .7},
                            hidden: { opacity: 0, scale: 0}
                          }} src={docker} className='docker parallax-child' alt="docker logo" />
                        
                          <motion.img initial="hidden"
                          whileInView="visible"
                          viewport={{ once: true }}
                          transition={{ type: 'spring', duration: .6, delay: .75}}
                          variants={{
                            visible: { scale: .7},
                            hidden: { opacity: 0, scale: 0}
                          }} src={github} className='github parallax-child' alt="github logo" />
                        
                          <motion.img initial="hidden"
                          whileInView="visible"
                          viewport={{ once: true }}
                          transition={{ type: 'spring', duration: .6, delay: 1.1}}
                          variants={{
                            visible: { scale: .7},
                            hidden: { opacity: 0, scale: 0}
                          }} src={gitlab} className='gitlab parallax-child' alt="gitlab logo" />
                        
                          <motion.img initial="hidden"
                          whileInView="visible"
                          viewport={{ once: true }}
                          transition={{ type: 'spring', duration: .6, delay: .9}}
                          variants={{
                            visible: { scale: .7},
                            hidden: { opacity: 0, scale: 0}
                          }} src={googleCloud} className='googleCloud parallax-child' alt="googleCloud logo" />
                        
                          <motion.img initial="hidden"
                          whileInView="visible"
                          viewport={{ once: true }}
                          transition={{ type: 'spring', duration: .6, delay: 1}}
                          variants={{
                            visible: { scale: .7},
                            hidden: { opacity: 0, scale: 0}
                          }} src={grafanaLoki} className='grafanaLoki parallax-child' alt="grafanaLoki logo" />
                        
                          <motion.img initial="hidden"
                          whileInView="visible"
                          viewport={{ once: true }}
                          transition={{ type: 'spring', duration: .6, delay: 1.2}}
                          variants={{
                            visible: { scale: .7},
                            hidden: { opacity: 0, scale: 0}
                          }} src={grafana} className='grafana parallax-child' alt="grafana logo" />
                        
                          <motion.img initial="hidden"
                          whileInView="visible"
                          viewport={{ once: true }}
                          transition={{ type: 'spring', duration: .6, delay: .8}}
                          variants={{
                            visible: { scale: .7},
                            hidden: { opacity: 0, scale: 0}
                          }} src={helm} className='helm parallax-child' alt="helm logo" />
                        
                          <motion.img initial="hidden"
                          whileInView="visible"
                          viewport={{ once: true }}
                          transition={{ type: 'spring', duration: .6, delay: .7}}
                          variants={{
                            visible: { scale: .7},
                            hidden: { opacity: 0, scale: 0}
                          }} src={jenkins} className='jenkins parallax-child' alt="jenkins logo" />
                        
                          <motion.img initial="hidden"
                          whileInView="visible"
                          viewport={{ once: true }}
                          transition={{ type: 'spring', duration: .6, delay: 1}}
                          variants={{
                            visible: { scale: .7},
                            hidden: { opacity: 0, scale: 0}
                          }} src={kubernetes} className='kubernetes parallax-child' alt="kubernetes logo" />
                        
                          <motion.img initial="hidden"
                          whileInView="visible"
                          viewport={{ once: true }}
                          transition={{ type: 'spring', duration: .6, delay: .9}}
                          variants={{
                            visible: { scale: .7},
                            hidden: { opacity: 0, scale: 0}
                          }} src={nexus} className='nexus parallax-child' alt="nexus logo" />
                        
                          <motion.img initial="hidden"
                          whileInView="visible"
                          viewport={{ once: true }}
                          transition={{ type: 'spring', duration: .6, delay: .8}}
                          variants={{
                            visible: { scale: .7},
                            hidden: { opacity: 0, scale: 0}
                          }} src={prometheus} className='prometheus parallax-child' alt="prometheus logo" />
                        
                          <motion.img initial="hidden"
                          whileInView="visible"
                          viewport={{ once: true }}
                          transition={{ type: 'spring', duration: .6, delay: 1}}
                          variants={{
                            visible: { scale: .7},
                            hidden: { opacity: 0, scale: 0}
                          }} src={redHat} className='redHat parallax-child' alt="redHat logo" />
                        
                          <motion.img initial="hidden"
                          whileInView="visible"
                          viewport={{ once: true }}
                          transition={{ type: 'spring', duration: .6, delay: .8}}
                          variants={{
                            visible: { scale: .7},
                            hidden: { opacity: 0, scale: 0}
                          }} src={sonarqube} className="sonarqube parallax-child"  alt="sonarqube logo" />
                        
                          <motion.img initial="hidden"
                          whileInView="visible"
                          viewport={{ once: true }}
                          transition={{ type: 'spring', duration: .6, delay: .8}}
                          variants={{
                            visible: { scale: .7},
                            hidden: { opacity: 0, scale: 0}
                          }} src={terraform} className="terraform parallax-child" alt="terraform logo" />

                    </div>
                ) : (
                  <MouseParallaxContainer globalFactorX={0.2} globalFactorY={0.2}>
                    <div className="container-brands">

                      <motion.h2
                       initial="hidden"
                       whileInView="visible"
                       viewport={{ once: true }}
                       transition={{ type: 'spring', duration: .6, delay: .3}}
                       variants={{
                         visible: { opacity: 1, scale: 1},
                         hidden: { opacity: 0, scale: .5}
                       }}
                      >Some of the <span>most popular tools</span> <b>we use</b></motion.h2>

                        <MouseParallaxChild className='ansible parallax-child' factorX={1} factorY={1} >
                          <motion.img initial="hidden"
                          whileInView="visible"
                          viewport={{ once: true }}
                          transition={{ type: 'spring', duration: 1, delay: .8}}
                          variants={{
                            visible: { opacity: 1, scale: 1},
                            hidden: { opacity: 0, scale: .2}
                          }} src={ansible} alt="ansible logo" />
                        </MouseParallaxChild>
                        
                        <MouseParallaxChild className='aws parallax-child' factorX={0.5} factorY={0.5}>
                        <motion.img initial="hidden"
                          whileInView="visible"
                          viewport={{ once: true }}
                          transition={{ type: 'spring', duration: 1, delay: 1.1}}
                          variants={{
                            visible: { opacity: 1, scale: 1},
                            hidden: { opacity: 0, scale: .2}
                          }} src={aws} alt="aws logo" />
                        </MouseParallaxChild>
                        
                        <MouseParallaxChild className='azure parallax-child' factorX={0.7} factorY={0.7}>
                          <motion.img initial="hidden"
                          whileInView="visible"
                          viewport={{ once: true }}
                          transition={{ type: 'spring', duration: 1, delay: 1}}
                          variants={{
                            visible: { opacity: 1, scale: 1},
                            hidden: { opacity: 0, scale: .2}
                          }} src={azure} alt="azure logo" />
                        </MouseParallaxChild>
                        
                        <MouseParallaxChild className='codepipeline parallax-child' factorX={1} factorY={1}>
                          <motion.img initial="hidden"
                          whileInView="visible"
                          viewport={{ once: true }}
                          transition={{ type: 'spring', duration: 1, delay: .9}}
                          variants={{
                            visible: { opacity: 1, scale: 1},
                            hidden: { opacity: 0, scale: .2}
                          }} src={codepipeline} alt="codepipeline logo" />
                        </MouseParallaxChild>
                        
                        <MouseParallaxChild className='docker parallax-child' factorX={.9} factorY={.9}>
                          <motion.img initial="hidden"
                          whileInView="visible"
                          viewport={{ once: true }}
                          transition={{ type: 'spring', duration: 1, delay: .7}}
                          variants={{
                            visible: { opacity: 1, scale: 1},
                            hidden: { opacity: 0, scale: .2}
                          }} src={docker} alt="docker logo" />
                        </MouseParallaxChild>
                        
                        <MouseParallaxChild className='github parallax-child'  factorX={1.4} factorY={1.6}>
                          <motion.img initial="hidden"
                          whileInView="visible"
                          viewport={{ once: true }}
                          transition={{ type: 'spring', duration: 1, delay: 1}}
                          variants={{
                            visible: { opacity: 1, scale: 1},
                            hidden: { opacity: 0, scale: .2}
                          }} src={github} alt="github logo" />
                        </MouseParallaxChild>
                        
                        <MouseParallaxChild className='gitlab parallax-child'  factorX={1.4} factorY={1.6}>
                          <motion.img initial="hidden"
                          whileInView="visible"
                          viewport={{ once: true }}
                          transition={{ type: 'spring', duration: 1, delay: 1.1}}
                          variants={{
                            visible: { opacity: 1, scale: 1},
                            hidden: { opacity: 0, scale: .2}
                          }} src={gitlab} alt="gitlab logo" />
                        </MouseParallaxChild>
                        
                        <MouseParallaxChild className='googleCloud parallax-child' factorX={.9} factorY={.9}>
                          <motion.img initial="hidden"
                          whileInView="visible"
                          viewport={{ once: true }}
                          transition={{ type: 'spring', duration: 1, delay: 1}}
                          variants={{
                            visible: { opacity: 1, scale: 1},
                            hidden: { opacity: 0, scale: .2}
                          }} src={googleCloud} alt="googleCloud logo" />
                        </MouseParallaxChild>
                        
                        <MouseParallaxChild className='grafanaLoki parallax-child' factorX={.6} factorY={.6}>
                          <motion.img initial="hidden"
                          whileInView="visible"
                          viewport={{ once: true }}
                          transition={{ type: 'spring', duration: 1, delay: .8}}
                          variants={{
                            visible: { opacity: 1, scale: 1},
                            hidden: { opacity: 0, scale: .2}
                          }} src={grafanaLoki} alt="grafanaLoki logo" />
                        </MouseParallaxChild>
                        
                        <MouseParallaxChild className='grafana parallax-child' factorX={.7} factorY={.7}>
                          <motion.img initial="hidden"
                          whileInView="visible"
                          viewport={{ once: true }}
                          transition={{ type: 'spring', duration: 1, delay: .9}}
                          variants={{
                            visible: { opacity: 1, scale: 1},
                            hidden: { opacity: 0, scale: .2}
                          }} src={grafana} alt="grafana logo" />
                        </MouseParallaxChild>
                        
                        <MouseParallaxChild className='helm parallax-child' factorX={1.4} factorY={1.6}>
                          <motion.img initial="hidden"
                          whileInView="visible"
                          viewport={{ once: true }}
                          transition={{ type: 'spring', duration: 1, delay: 1.1}}
                          variants={{
                            visible: { opacity: 1, scale: 1},
                            hidden: { opacity: 0, scale: .2}
                          }} src={helm} alt="helm logo" />
                        </MouseParallaxChild>
                        
                        <MouseParallaxChild className='jenkins parallax-child'  factorX={1.4} factorY={1.6}>
                          <motion.img initial="hidden"
                          whileInView="visible"
                          viewport={{ once: true }}
                          transition={{ type: 'spring', duration: 1, delay: 1.12}}
                          variants={{
                            visible: { opacity: 1, scale: 1},
                            hidden: { opacity: 0, scale: .2}
                          }} src={jenkins} alt="jenkins logo" />
                        </MouseParallaxChild>
                        
                        <MouseParallaxChild className='kubernetes parallax-child' factorX={1.3} factorY={1.5}>
                          <motion.img initial="hidden"
                          whileInView="visible"
                          viewport={{ once: true }}
                          transition={{ type: 'spring', duration: 1, delay: 1.2}}
                          variants={{
                            visible: { opacity: 1, scale: 1},
                            hidden: { opacity: 0, scale: .2}
                          }} src={kubernetes} alt="kubernetes logo" />
                        </MouseParallaxChild>
                        
                        <MouseParallaxChild className='nexus parallax-child' factorX={.8} factorY={.8}>
                          <motion.img initial="hidden"
                          whileInView="visible"
                          viewport={{ once: true }}
                          transition={{ type: 'spring', duration: 1, delay: .8}}
                          variants={{
                            visible: { opacity: 1, scale: 1},
                            hidden: { opacity: 0, scale: .2}
                          }} src={nexus} alt="nexus logo" />
                        </MouseParallaxChild>
                        
                        <MouseParallaxChild className='prometheus parallax-child' factorX={.9} factorY={.9}>
                          <motion.img initial="hidden"
                          whileInView="visible"
                          viewport={{ once: true }}
                          transition={{ type: 'spring', duration: 1, delay: .9}}
                          variants={{
                            visible: { opacity: 1, scale: 1},
                            hidden: { opacity: 0, scale: .2}
                          }} src={prometheus} alt="prometheus logo" />
                        </MouseParallaxChild>
                        
                        <MouseParallaxChild className='redHat parallax-child' factorX={.7} factorY={.7}>
                          <motion.img initial="hidden"
                          whileInView="visible"
                          viewport={{ once: true }}
                          transition={{ type: 'spring', duration: 1, delay: .8}}
                          variants={{
                            visible: { opacity: 1, scale: 1},
                            hidden: { opacity: 0, scale: .2}
                          }} src={redHat} alt="redHat logo" />
                        </MouseParallaxChild>
                        
                        <MouseParallaxChild className="sonarqube parallax-child" factorX={.9} factorY={.9}>
                          <motion.img initial="hidden"
                          whileInView="visible"
                          viewport={{ once: true }}
                          transition={{ type: 'spring', duration: 1, delay: 1}}
                          variants={{
                            visible: { opacity: 1, scale: 1},
                            hidden: { opacity: 0, scale: .2}
                          }} src={sonarqube} alt="sonarqube logo" />
                        </MouseParallaxChild>
                        
                        <MouseParallaxChild className="terraform parallax-child" factorX={.6} factorY={.6}>
                          <motion.img initial="hidden"
                          whileInView="visible"
                          viewport={{ once: true }}
                          transition={{ type: 'spring', duration: 1, delay: .8}}
                          variants={{
                            visible: { opacity: 1, scale: 1},
                            hidden: { opacity: 0, scale: .2}
                          }} src={terraform} alt="terraform logo" />
                        </MouseParallaxChild>

                    </div>
                  </MouseParallaxContainer>
                )
              }
              
            </section>

            <section className="monitoring-systems">
              <article className="section-container container">
                <motion.h2
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true }}
                  transition={{ duration: .5, delay: .2 }}
                  variants={{
                    visible: { opacity: 1, y: 0},
                    hidden: { opacity: 0, y: '10px'}
                  }}
                >
                  Innovation Meets Affordability
                </motion.h2>

                <div className="row">
                  <motion.div 
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true }}
                  transition={{ duration: .5, delay: .4 }}
                  variants={{
                    visible: { opacity: 1},
                    hidden: { opacity: 0}
                  }}
                  className="development-col" style={{gridArea: 'fr'}}>
                    <h3>
                      <small>up to</small>
                      <div>
                        <div>
                          <p className="main_value" ref={devEl}>20</p><p>%</p>
                        </div>
                        <span>Saved</span>
                      </div>
                      <p>
                      development & maintenance costs
                      </p>
                    </h3>

                    <motion.div 
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true }}
                    transition={{ duration: .5, delay: .7 }}
                    variants={{
                      visible: { opacity: 1},
                      hidden: { opacity: 0}
                    }}>
                      <p>Experience a <strong>game-changing shift</strong> in your application development with Sheldonstack! Enjoy a <strong>remarkable 68% reduction in deployment, maintenance, and development costs.</strong> Elevate your efficiency and maximize your resources with Sheldonstack's innovative solutions.
                      </p>
                    </motion.div>
                  </motion.div>

                  <motion.div 
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true }}
                  transition={{ duration: .5, delay: .4 }}
                  variants={{
                    visible: { opacity: 1},
                    hidden: { opacity: 0}
                  }}
                  className="infra_cost-col" style={{gridArea: 'fs'}}>
                    <h3>
                      <small>up to</small>
                      <div>
                        <div>
                          <p className="main_value" ref={infraEl}>5</p><p>%</p>
                        </div>
                        <span>Saved</span>
                      </div>
                      <p>
                      infra costs
                      </p>
                    </h3>

                    <motion.div 
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true }}
                    transition={{ duration: .6, delay: .7 }}
                    variants={{
                      visible: { opacity: 1},
                      hidden: { opacity: 0}
                    }}>

                      <p>Unlock substantial savings <strong>of 24% on infrastructure costs</strong> by choosing our solution. Leveraging strategic partnerships with <strong>AWS and Azure</strong>, we provide top-tier solutions at remarkably competitive prices. Join us in optimizing your expenses while enjoying <strong>the highest quality infrastructure services.</strong></p>
                    </motion.div>
                  </motion.div>

                  <motion.div 
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true }}
                  transition={{ duration: .5, delay: .4 }}
                  variants={{
                    visible: { opacity: 1},
                      hidden: { opacity: 0}
                  }} 
                  className="speed_up-col" style={{gridArea: 'ft', alignSelf: 'center'}}>
                    <h3>
                      <small>up to</small>
                      <div>
                        <div>
                          <p className="main_value" ref={speedEl} >0</p><p>x</p>
                        </div>
                        <span>Faster</span>
                      </div>
                      <p>
                      development process
                      </p>
                    </h3>
                    <motion.div 
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true }}
                    transition={{ duration: .5, delay: .9 }}
                    variants={{
                      visible: { opacity: 1},
                      hidden: { opacity: 0}
                    }}>
                      <p><strong>Revolutionize your development process</strong> with our cutting-edge solution, <strong>accelerating your pace by an impressive 5 times!</strong> Say goodbye to sluggish development and embrace unprecedented speed and efficiency with our platform.
                      </p>
                    </motion.div>
                  </motion.div>
                </div>
              </article>
            </section>

            {/* <section className="plans">
              <div className="container">
                <motion.h2
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ duration: .5, delay: .2 }}
                variants={{
                  visible: { opacity: 1, y: 0},
                  hidden: { opacity: 0, y: '10px'}
                }}
                >Available plans</motion.h2>
                <div className="plans-container">
                    {swiperPlans ? (
                      <Swiper
                        slidesPerView={1.8}
                        initialSlide={1}
                        centeredSlides={true}
                        slideToClickedSlide={true}
                        breakpoints={{
                          680: {
                            slidesPerView: 2,
                          },
                          865: {
                            slidesPerView: 2.3,
                          },
                          920: {
                            slidesPerView: 2.4,
                          },
                          1000: {
                            slidesPerView: 2.7,
                          },
                        }}
                      >
                        <SwiperSlide>
                        <motion.div 
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true }}
                            transition={{ duration: .5, delay: .2 }}
                            variants={{
                              visible: { opacity: 1, y: 0},
                              hidden: { opacity: 0, y: '10px'}
                            }} 
                            className="silver">
                            <div className="box">
                              <h2 className="plan-name">Silver</h2>
                              <p>For personal use</p>
                              <h3 className='price'><span>From</span> 99,99<p>€</p><span>/Mo</span></h3>
                                <a href="https://www.5red.io/contact-us/">get started</a>
                            </div>
                            <ul className="benefits">
                              <li>Private or Public Cloud</li>
                              <li>Fully automated & managed processes</li>
                              <li>Basic Tech Stack incl. CI/CD Tools</li>
                              <li>Basic Monitoring Tools</li>
                              <li>Bastion Host to Access Infrastructure</li>
                              <li>2 environment (dev/prod)</li>
                              <li>One change request a month</li>
                            </ul>
                          </motion.div>
                        </SwiperSlide>
                        <SwiperSlide>
                        <motion.div 
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true }}
                        transition={{ duration: .5, delay: .2 }}
                        variants={{
                          visible: { opacity: 1, y: 0},
                          hidden: { opacity: 0, y: '10px'}
                        }} className="gold">
                          <div className="box">
                            <h2 className="plan-name">Gold</h2>
                            <p>Best for startups</p>
                            <h3 className='price-req'>Price on request</h3>
                            <a href="https://www.5red.io/contact-us/">get started</a>
                          </div>
                          <ul className="benefits">
                            <li>Private or Public Cloud</li>
                            <li>Fully automated & managed processes</li>
                            <li>Advanced Tech Stack incl. customised CI/CD Tools</li>
                            <li>Application monitoring</li>
                            <li>Support in business hours</li>
                            <li>OD/GD in business hours</li>
                            <li>VPN (incl. Site to Site)</li>
                            <li>3 environment (dev/stage/prod)</li>
                            <li>Blue Green Deployments</li>
                            <li>Ten change request a month</li>
                          </ul>
                        </motion.div>
                        </SwiperSlide>
                        <SwiperSlide>
                        <motion.div 
                          initial="hidden"
                          whileInView="visible"
                          viewport={{ once: true }}
                          transition={{ duration: .5, delay: .2 }}
                          variants={{
                            visible: { opacity: 1, y: 0},
                            hidden: { opacity: 0, y: '10px'}
                          }} 
                          className="diamond"
                        >
                          <div className="box">
                            <h2 className="plan-name">Diamond</h2>
                            <p>Best for big brands</p>
                            <h3 className='price-req'>Price on request</h3>
                            <a href="https://www.5red.io/contact-us/">get started</a>
                          </div>
                          <ul className="benefits">
                            <li>Private or Public Cloud</li>
                            <li>Fully automated & managed processes</li>
                            <li>Advanced Tech Stack incl. customized CI/CD Tools</li>
                            <li>Unlimited number of additional tools</li>
                            <li>Advanced monitoring application and infra monitoring incl. Logging</li>
                            <li>Support in business hours</li>
                            <li>OD/GD 24/7</li>
                            <li>VPN (incl. Site to Site)</li>
                            <li>Unlimited number of environments</li>
                            <li>Blue Green Deployments</li>
                            <li>SLA 99,99%</li>
                            <li>Unlimited Custom Options</li>
                            <li>Unlimited change requests</li>
                          </ul>
                        </motion.div>
                        </SwiperSlide>
                    
                      </Swiper>
                  ) : (
                      <>
                          <motion.div 
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true }}
                            transition={{ duration: .5, delay: .2 }}
                            variants={{
                              visible: { opacity: 1, y: 0},
                              hidden: { opacity: 0, y: '10px'}
                            }} 
                            className="silver">
                            <div className="box">
                              <h2 className="plan-name">Silver</h2>
                              <p>For personal use</p>
                              <h3 className='price'><span>From</span> 199,99<p>€</p><span>/Mo</span></h3>
                                <a href="https://www.5red.io/contact-us/">
                                  get started
                                </a>
                            </div>
                            <ul className="benefits">
                              <li>Private or Public Cloud</li>
                              <li>Fully automated & managed processes</li>
                              <li>Basic Tech Stack incl. CI/CD Tools</li>
                              <li>Basic Monitoring Tools</li>
                              <li>Bastion Host to Access Infrastructure</li>
                              <li>2 environment (dev/prod)</li>
                              <li>One change request a month</li>
                            </ul>
                          </motion.div>

                          <motion.div 
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true }}
                            transition={{ duration: .5, delay: .2 }}
                            variants={{
                              visible: { opacity: 1, y: 0},
                              hidden: { opacity: 0, y: '10px'}
                            }} 
                            className="gold">
                          <div className="box">
                            <h2 className="plan-name">Gold</h2>
                            <p>Best for startups</p>
                            <h3 className='price-req'>Price on request</h3>
                            <a href="https://www.5red.io/contact-us/">get started</a>
                          </div>
                          <ul className="benefits">
                            <li>Private or Public Cloud</li>
                            <li>Fully automated & managed processes</li>
                            <li>Advanced Tech Stack incl. customised CI/CD Tools</li>
                            <li>Application monitoring</li>
                            <li>Support in business hours</li>
                            <li>OD/GD in business hours</li>
                            <li>VPN (incl. Site to Site)</li>
                            <li>3 environment (dev/stage/prod)</li>
                            <li>Blue Green Deployments</li>
                            <li>Ten change request a month</li>
                          </ul>
                        </motion.div>

                        <motion.div 
                          initial="hidden"
                          whileInView="visible"
                          viewport={{ once: true }}
                          transition={{ duration: .5, delay: .2 }}
                          variants={{
                            visible: { opacity: 1, y: 0},
                            hidden: { opacity: 0, y: '10px'}
                          }}
                          className="diamond">
                          <div className="box">
                            <h2 className="plan-name">Diamond</h2>
                            <p>Best for big brands</p>
                            <h3 className='price-req'>Price on request</h3>
                            <a href="https://www.5red.io/contact-us/">get started</a>
                          </div>
                          <ul className="benefits">
                            <li>Private or Public Cloud</li>
                            <li>Fully automated & managed processes</li>
                            <li>Advanced Tech Stack incl. customized CI/CD Tools</li>
                            <li>Unlimited number of additional tools</li>
                            <li>Advanced monitoring application and infra monitoring incl. Logging</li>
                            <li>Support in business hours</li>
                            <li>OD/GD 24/7</li>
                            <li>VPN (incl. Site to Site)</li>
                            <li>Unlimited number of environments</li>
                            <li>Blue Green Deployments</li>
                            <li>SLA 99,99%</li>
                            <li>Unlimited Custom Options</li>
                            <li>Unlimited change requests</li>
                          </ul>
                        </motion.div>
                    </>
                  )}
                </div>
              </div>
            </section> */}

            <section className="world-wide">
              <div className="container">
              <motion.h2
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ duration: .5, delay: .2 }}
                variants={{
                  visible: { opacity: 1, y: 0},
                  hidden: { opacity: 0, y: '10px'}
                }}
                >Proudly used by companies worldwide.</motion.h2>

                <div className="world-wide__content">
                  <div className="partners">

                  <motion.img
                    initial="hidden"
                    whileInView="visible"
                    className="partner-logo"
                    viewport={{ once: true }}
                    transition={{ duration: .5, delay: 1.1 }}
                    variants={{
                      visible: { opacity: 1, y: 0},
                      hidden: {opacity: 0, y: 50}
                    }}
                    src={prait} alt="world-wide illustration" />
                  
                  <motion.img
                    initial="hidden"
                    whileInView="visible"
                    className="partner-logo"
                    viewport={{ once: true }}
                    transition={{ duration: .5, delay: 1.1 }}
                    variants={{
                      visible: { opacity: 1, y: 0},
                      hidden: {opacity: 0, y: 50}
                    }}
                    src={jungwind} alt="world-wide illustration" />
                  <motion.img
                    initial="hidden"
                    whileInView="visible"
                    className="partner-logo"
                    viewport={{ once: true }}
                    transition={{ duration: .5, delay: 1.1 }}
                    variants={{
                      visible: { opacity: 1, y: 0},
                      hidden: {opacity: 0, y: 50}
                    }}
                    src={mutlaq} alt="world-wide illustration" />
                    <motion.img
                    initial="hidden"
                    whileInView="visible"
                    className="partner-logo"
                    viewport={{ once: true }}
                    transition={{ duration: .5, delay: 1.1 }}
                    variants={{
                      visible: { opacity: 1, y: 0},
                      hidden: {opacity: 0, y: 50}
                    }}
                    src={fiveRed} alt="world-wide illustration" />
                  </div>
                </div>
              </div>
            </section>
        </div>
    );
}

export default HomePage;