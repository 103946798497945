import logo  from '../../images/brand files/SheldonStack-logo-light.png';

function TopBar() {
    return (
        <header className='top-bar-logo'>
             <img src={logo} alt="SheldonStack product logo" />
        </header>
    );
}


export default TopBar;