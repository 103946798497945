// import './fonts.css';
import './style.css';
import TopBar from './components/TopBar/TopBar';
import Footer from './components/Footer/Footer';
import HomePage from './pages/HomePage/HomePage';

function App() {
  return (
    <div className="App">
      <TopBar />
      <HomePage/>
      <Footer />
    </div>
  );
}

export default App;
